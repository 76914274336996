.navbar {
  padding: 0.8rem 1rem;
  width: 100%;
  background-color: #394943;
  top: 0;
  position: fixed;
  box-sizing: border-box;
  overflow-x: scroll;
}

.navbar a {
  text-decoration: none;
  color: #f4f6f1;
  padding: 0 1rem;
}

.navbar a:first-child {
  padding-left: 0;
}

@media (min-width: 769px) {
  .navbar {
    padding: 1rem 3rem;
  }
}

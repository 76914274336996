html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6, 
.heading--h4,
.heading--h5,
.heading--h6 {
  font-family: "Prata", Serif;
  -webkit-margin-after: 0%;
          margin-block-end: 0%;
  font-weight: bold;
}

h1 {
  font-size: 2.8rem;
}

h2 {
  font-size: 2.4rem;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 1.2rem;
          margin-block-end: 1.2rem;
}

h3 {
  font-size: 1.8rem;
  -webkit-margin-before: 1rem;
          margin-block-start: 1rem;
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
}

h4, .heading--h4 {
  font-size: 1.6rem;
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
}

h5, .heading--h5 {
  font-size: 1.4rem;
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
}

h6, .heading--h6 {
  font-size: 1rem;
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
}

a {
  color: #976371;
}

a:hover {
  font-weight: 800;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
}

@media (min-width: 769px) {
  html {
    font-size: 18px;
  }
}





.navbar {
  padding: 0.8rem 1rem;
  width: 100%;
  background-color: #394943;
  top: 0;
  position: fixed;
  box-sizing: border-box;
  overflow-x: scroll;
}

.navbar a {
  text-decoration: none;
  color: #f4f6f1;
  padding: 0 1rem;
}

.navbar a:first-child {
  padding-left: 0;
}

@media (min-width: 769px) {
  .navbar {
    padding: 1rem 3rem;
  }
}

.jumbotron_jumbotron__1d9Ck {
  background-image: url(/static/media/jumbotron.09cca4eb.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0%;
  text-align: center;
  height: 100vh;
  color: #f4f6f1;
  top: 0;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .projects_grid__119Xc {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto auto;
    grid-gap: 20px;
  }
}

@media (max-width: 1023px) {
  .projects_grid__119Xc {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto auto;
    grid-gap: 20px;
  }
}

@media (max-width: 767px) {
  .projects_grid__119Xc {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto auto;
    grid-gap: 20px;
  }
}

.projects_grid_item__THiBM {
  padding: 8%;
  background-color: #f4f6f1;
  color: #394943;
  text-align: center;
  border-radius: 5px 5px;
  box-shadow: 5px 5px #242b28;
}

.projects_grid_item__THiBM:hover {
  background-color: #976371;
  color: #f4f6f1;
  transition-duration: 0.5s;
}

.projects_date__1Yz72 {
  margin-top: 0;
}

a {
  color: #394943;
}

.resume_grid__3lNPx {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3%;
}

@media (max-width: 767px) {
  .resume_grid__3lNPx {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3%;
  }
}

ul {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}

li {
  list-style-type: circle;
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
}

.press_pressLink__2C97d {
  color: #f4f6f1;
}
.contact_image__39DhD {
  width: 2rem;
  padding-right: 1rem;
}

.contact_socialsList__2H0QM {
  padding-left: 0;
}

.contact_socialsList__2H0QM li {
  display: inline;
  list-style: none;
}

@media (min-width: 769px) {
  .contact_image__39DhD {
    padding-right: 1.2rem;
  }
}
footer {
  text-align: center;
  bottom: 0;
  padding: 1rem 0%;
  width: 100%;
  font-size: 0.7rem;
  background-color: #394943;
  color: #f4f6f1;
}
.App {
  text-align: left;
  min-height: 100vh;
  width: 100vw;
}

.light {
  padding: 2rem 1rem;
  color: #394943;
  background-color: #f4f6f1;
}

.dark {
  padding: 2rem 1rem;
  color: #f4f6f1;
  background-color: #394943;
}

.wrapper {
  max-width: 1280px;
  margin: auto;
}

@media (min-width: 769px) {
  .light, .dark {
    padding: 3rem;
  }
}

/*colors

light-shade: #F4F6F1;
light-accent: #82846F;
main: #9CA0A2;
dark-accent: #976371;
dark-shade: #394943;

color-semantics

primary-grey: #9ba0a2;
primary-black: #394943;
success: #64ab69;
warning: #e19a31;
danger: #e19a31;

*/


.jumbotron {
  background-image: url("../../assets/jumbotron.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0%;
  text-align: center;
  height: 100vh;
  color: #f4f6f1;
  top: 0;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.App {
  text-align: left;
  min-height: 100vh;
  width: 100vw;
}

.light {
  padding: 2rem 1rem;
  color: #394943;
  background-color: #f4f6f1;
}

.dark {
  padding: 2rem 1rem;
  color: #f4f6f1;
  background-color: #394943;
}

.wrapper {
  max-width: 1280px;
  margin: auto;
}

@media (min-width: 769px) {
  .light, .dark {
    padding: 3rem;
  }
}

/*colors

light-shade: #F4F6F1;
light-accent: #82846F;
main: #9CA0A2;
dark-accent: #976371;
dark-shade: #394943;

color-semantics

primary-grey: #9ba0a2;
primary-black: #394943;
success: #64ab69;
warning: #e19a31;
danger: #e19a31;

*/

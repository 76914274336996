.image {
  width: 2rem;
  padding-right: 1rem;
}

.socialsList {
  padding-left: 0;
}

.socialsList li {
  display: inline;
  list-style: none;
}

@media (min-width: 769px) {
  .image {
    padding-right: 1.2rem;
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3%;
}

@media (max-width: 767px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3%;
  }
}

ul {
  padding-inline-start: 20px;
}

li {
  list-style-type: circle;
  margin-block-start: 10px;
}
